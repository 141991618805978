import React, { useMemo } from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import config from '../utils/config'

export default function MeIndex({ data, ...props }) {

  return (
    <Layout>
      <Helmet title={`About Me | ${config.siteTitle}`} />
      <SEO customDescription="Articles, tutorials, snippets, musings, and everything else." />
      <header>
        <div className="container">
          <h1>About Me.</h1>
          <p className="subtitle">
            Posts, tutorials, snippets, musings, notes, and everything else. The
            archive of everything I've written.
          </p>
        </div>
      </header>
      {/* <section>
        <div className="container">
          <Search posts={simplifiedPosts} {...props} />
        </div>
      </section> */}
    </Layout>
  )
}

